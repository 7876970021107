import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loadUser, reducer as oidcReducer } from 'redux-oidc';
import thunk from 'redux-thunk';
import userManager from './auth/IdentityServer';
import commonReducer from './components/core/commonReducer';

const rootReducer = combineReducers({
    form: formReducer,
    oidc: oidcReducer,
    ...commonReducer,
});

const store = (() => {
    const middleware = applyMiddleware(thunk);
    const createStoreWithMiddleware = compose(middleware);
    return createStoreWithMiddleware(createStore)(
        rootReducer
        // ,
        // window.__REDUX_DEVTOOLS_EXTENSION__ &&
        //     window.__REDUX_DEVTOOLS_EXTENSION__()
    );
})();

loadUser(store, userManager);

export default store;
