import createReducer from '../../createReducer';

const initialState = {
    data: {},
    error: null,
    loaded: false,
};

const handlers = {
    LOAD_PROMOTIONAL_VIDEO_SUCCESS: (state, action) => {
        console.log('LOAD_PROMOTIONAL_VIDEO_SUCCESS');

        let data = action.payload;

        return {
            data: data,
            error: null,
            loaded: true,
        };
    },
    LOAD_PROMOTIONAL_VIDEO_FAILURE: (state, action) => {
        console.log('LOAD_PROMOTIONAL_VIDEO_FAILURE');

        console.log('Failed Loading Promotional Videos Page: ', action.payload);
        return {
            ...state,
        };
    },

    UPDATE_PROMOTIONAL_VIDEO_SUCCESS: (state, action) => {
        console.log('UPDATE_PROMOTIONAL_VIDEO_SUCCESS');

        let prevData = state.data;

        let newData = action.payload;

        return {
            ...state,
            data: {
                ...prevData,
                ...newData,
            },
        };
    },
};

export default createReducer(initialState, handlers);
