import React, { Component } from 'react';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../../auth/IdentityServer';
import history from '../../core/utils/history';

class Callback extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={user => {
                    if (
                        user &&
                        user.profile &&
                        user.profile.role &&
                        (user.profile.role.includes('HigherEdEmployee') ||
                            user.profile.role.includes('Admin'))
                    ) {
                        history.push('/');
                    } else {
                        console.log('Failed');
                        history.push({
                            pathname: '/login',
                            state: {
                                status: 401,
                            },
                        });
                    }
                }}
                errorCallback={error => {
                    console.log('Error Login', error);
                }}
            >
                <div />
            </CallbackComponent>
        );
    }
}

export default Callback;
