import createReducer from '../createReducer';

const initialState = {
    all: {},
    list: [],
    sgToken: null,
    sgTokenLoaded: false,
    error: null,
    loaded: false,
};

const handlers = {
    LOAD_NEWSLETTERS_SUCCESS: (state, action) => {
        console.log('LOAD_NEWSLETTERS_SUCCESS');

        const sgData = action.payload.result.result;
        const fbData = action.payload.fbData;
        let list = [];
        let all = {};

        if (sgData) {
            sgData.forEach(item => {
                if (item.editor !== 'design') {
                    all[item.id] = {
                        ...fbData[item.id],
                        ...item,
                    };
                    list.push({
                        ...fbData[item.id],
                        ...item,
                    });
                }
            });
        }

        return {
            ...state,
            all: all,
            list: list,
            error: null,
            loaded: true,
        };
    },
    LOAD_SGTOKEN_SUCCESS: (state, action) => {
        console.log('LOAD_SGTOKEN_SUCCESS');

        const data = action.payload;

        return {
            ...state,
            sgToken: data,
            sgTokenLoaded: true,
        };
    },
    UPDATE_NEWSLETTER_SUCCESS: (state, action) => {
        console.log('UPDATE_NEWSLETTER_SUCCESS');

        let allNewsletters = state.all;

        const data = action.payload;

        allNewsletters[data.id] = data;
        return {
            ...state,
            all: allNewsletters,
        };
    },
};

export default createReducer(initialState, handlers);
