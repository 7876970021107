import createReducer from '../createReducer';
import { formatDateTime } from '../utils/formatDate';

const initialState = {
    all: {},
    current: {
        data: [],
        totalCount: null,
    },
    usersByClaim: {
        all: {},
        list: [],
        loaded: false,
    },
    error: null,
    loaded: false,
    claimsLoaded: false,
    rolesLoaded: false,
    roles: {
        loaded: false,
        all: [],
    },
    languageData: {
        data: {},
        loaded: false,
    },
    degreeData: {
        data: {},
        loaded: false,
    },
    schoolData: {
        data: {},
        loaded: false,
    },
    candidateLoaded: false,

    identityData: {
        loaded: false,
        data: {},
        list: [],
    },
    chatUserOrganization: {
        loaded: false,
        error: false,
        data: {},
        list: [],
    },
    userStudentVerification: {
        loaded: false,
        data: {},
        list: [],
    },
    userStudentVerificationByEmail: {},
};

const formatDateStandard = d => {
    return d.substr(0, d.indexOf('.') + 3);
};

const handlers = {
    LOAD_USERS_REQUEST: (state, action) => {
        return {
            ...state,
            loaded: false,
        };
    },
    LOAD_USER_REQUEST: (state, action) => {
        return {
            ...state,
            loaded: false,
        };
    },
    LOAD_USER_SUCCESS: (state, action) => {
        const data = action.payload;

        let res;

        if (data) {
            res = {
                ...state.all[data.id],
                ...data,
                createdAt: formatDateStandard(data.createdAt),
            };
        }

        return {
            ...state,
            all: {
                ...state.all,
                [data.id]: res,
            },
            loaded: true,
        };
    },
    LOAD_USERS_SUCCESS: (state, action) => {
        const data = action.payload;
        let all = state.all;
        let list = [];

        if (data) {
            data.data.forEach(item => {
                let formattedDate = formatDateStandard(item.createdAt);
                let res = {
                    ...state.all[item.id],
                    ...item,
                    createdAt: formattedDate,
                    beautifulCreatedAt: formatDateTime(new Date(formattedDate)),
                };
                all[item.id] = res;
                list.push(res);
            });
        }

        return {
            ...state,
            all: all,
            current: {
                ...data,
                data: list,
            },
            error: null,
            loaded: true,
        };
    },
    LOAD_USER_BY_CLAIM_REQUEST: state => {
        return {
            ...state,
            usersByClaim: {
                all: {},
                list: [],
                loaded: false,
            },
        };
    },
    LOAD_USER_BY_CLAIM_SUCCESS: (state, action) => {
        const data = action.payload;

        const list = [];
        const all = {};

        if (data) {
            Object.keys(data).forEach(key => {
                if (data[key] && data[key].length) {
                    data[key].forEach(item => {
                        let _item = {
                            ...item,
                            role: key,
                        };
                        list.push(_item);
                        all[item.id] = _item;
                    });
                }
            });
        }

        return {
            ...state,
            usersByClaim: {
                loaded: true,
                all: all,
                list: list,
            },
        };
    },
    LOAD_USER_CLAIM_REQUEST: state => {
        return {
            ...state,
            claimsLoaded: false,
        };
    },
    LOAD_USER_CLAIM_SUCCESS: (state, action) => {
        const data = action.payload;
        const user_id = data.user_id;
        const claims = data.claims;
        const allUsers = state.all;

        allUsers[user_id] = { ...allUsers[user_id], claims: claims };
        return {
            ...state,
            all: allUsers,
            claimsLoaded: true,
        };
    },
    LOAD_ROLES_SUCCESS: (state, action) => {
        return {
            ...state,
            roles: {
                loaded: true,
                all: action.payload,
            },
        };
    },
    LOAD_USER_ROLES_FAILURE: (state, action) => {
        return {
            ...state,
            rolesLoaded: true,
            error: action.payload.error,
        };
    },
    LOAD_USER_ROLES_REQUEST: state => {
        return {
            ...state,
            rolesLoaded: false,
        };
    },
    LOAD_USER_ROLES_SUCCESS: (state, action) => {
        const data = action.payload;
        const user_id = data.user_id;
        const roles = data.roles;
        const allUsers = state.all;

        allUsers[user_id] = { ...allUsers[user_id], roles: roles };
        return {
            ...state,
            all: allUsers,
            rolesLoaded: true,
        };
    },
    LOAD_USER_CLAIM_FAILURE: (state, action) => {
        return {
            ...state,
            claimsLoaded: true,
            error: action.payload.error,
        };
    },
    LOAD_CANDIDATE_DETAILS_REQUEST: (state, action) => {
        return {
            ...state,
            candidateLoaded: false,
        };
    },
    LOAD_CANDIDATE_DETAILS_SUCCESS: (state, action) => {
        const data = action.payload;

        let newData = {};

        let degrees = [];
        let languages = [];

        if (data) {
            data.languages &&
                data.languages.forEach(item => {
                    languages.push({
                        languageId: item.languageId,
                        proficiencyId: item.proficiencyId,
                        id: item.id,
                    });
                });
            data.degrees &&
                data.degrees.forEach(item => {
                    degrees.push({
                        field_id: item.field_id,
                        level_id: item.level_id,
                        school_id: item.school_id,
                        school_name: item.school && item.school.name,
                        start: item.start.substr(0, item.start.indexOf('T')),
                        end:
                            item.end &&
                            item.end.substr(0, item.end.indexOf('T')),
                        name: item.name,
                    });
                });

            newData = {
                ...state.all[data.user_id],
                ...data,
                languages: languages,
                degrees: degrees,
            };
        }
        return {
            ...state,
            all: {
                ...state.all,
                [data.user_id]: newData,
            },
            candidateLoaded: true,
        };
    },
    LOAD_LANGUAGES_SUCCESS: (state, action) => {
        return {
            ...state,
            languageData: {
                data: action.payload,
                loaded: true,
            },
        };
    },
    LOAD_DEGREES_SUCCESS: (state, action) => {
        return {
            ...state,
            degreeData: {
                data: action.payload,
                loaded: true,
            },
        };
    },
    LOAD_CSCHOOLS_SUCCESS: (state, action) => {
        return {
            ...state,
            schoolData: {
                data: action.payload,
                loaded: true,
            },
        };
    },
    LOAD_IDENTITY_DATA_REQUEST: (state, action) => {
        return {
            ...state,
            identityData: {
                loaded: false,
                data: {},
                list: [],
            },
        };
    },
    LOAD_IDENTITY_DATA_SUCCESS: (state, action) => {
        const data = action.payload;
        let list = [];
        let obj = {};

        data.forEach(item => {
            let _item = {
                ...item,
                fullName: (item.firstName || '') + ' ' + (item.lastName || ''),
            };
            list.push(_item);
            obj[item.id] = _item;
        });
        return {
            ...state,
            identityData: {
                loaded: true,
                data: obj,
                list: list,
            },
        };
    },
    LOAD_CHAT_USER_ORGANIZATION_REQUEST: (state, action) => {
        return {
            ...state,
            chatUserOrganization: {
                loaded: false,
                error: false,
                data: {},
                list: [],
            },
        };
    },
    LOAD_CHAT_USER_ORGANIZATION_SUCCESS: (state, action) => {
        const data = action.payload.result;
        const userId = action.payload.userId;
        let list = [];
        let obj = {};

        data.filter(item => item.presence).forEach(item => {
            let _item = {
                presence: item.presence,
                userId: userId,
                organizationId: item.organizationId,
            };
            list.push(_item);
            obj[item.id] = _item;
        });

        return {
            ...state,
            chatUserOrganization: {
                loaded: true,
                error: false,
                data: obj,
                list: list,
            },
        };
    },
    LOAD_CHAT_USER_ORGANIZATION_FAILURE: (state, action) => {
        return {
            ...state,
            chatUserOrganization: {
                loaded: true,
                error: true,
                data: {},
                list: [],
            },
        };
    },
    LOAD_STUDENT_VERIFICATION_REQUEST: (state, action) => {
        return {
            ...state,
            userStudentVerification: {
                ...initialState.userStudentVerification,
            },
            userStudentVerificationByEmail: {},
        };
    },
    LOAD_STUDENT_VERIFICATION_SUCCESS: (state, action) => {
        let data = action.payload;

        let all = {};
        let list = [];

        if (data) {
            data.forEach(item => {
                let _item = {
                    id: item.id,
                    studentEmail: item.studentEmail,
                    isVerified: item.isVerified,
                    createdAt: item.createdAt,
                    schoolId: item.schoolId,
                    userId: item.userId,
                };
                all[item.id] = _item;
                list.push(_item);
            });
        }

        return {
            ...state,
            userStudentVerification: {
                data: all,
                list: list,
                loaded: true,
            },
        };
    },
    UPDATE_STUDENT_VERIFICATION_SUCCESS: (state, action) => {
        let data = action.payload;

        let newList = state.userStudentVerification.list;
        let newAll = state.userStudentVerification.data;

        if (data) {
            if (data.action === 'approve') {
                newAll[data.verificationId].isVerified = true;
                let index = newList.findIndex(
                    item => item.id === data.verificationId
                );
                if (index > -1) {
                    newList[index] = {
                        ...newList[index],
                        isVerified: true,
                    };
                }
            } else if (data.action === 'pending') {
                newAll[data.verificationId].isVerified = false;
                let index = newList.findIndex(
                    item => item.id === data.verificationId
                );
                if (index > -1) {
                    newList[index] = {
                        ...newList[index],
                        isVerified: false,
                    };
                }
            } else if (data.action === 'delete') {
                delete newAll[data.verificationId];
                let index = newList.findIndex(
                    item => item.id === data.verificationId
                );
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
        }
        return {
            ...state,
            userStudentVerification: {
                data: newAll,
                list: newList,
                loaded: true,
            },
        };
    },
    LOAD_STUDENT_VERIFICATION_BY_EMAIL_SUCCESS: (state, action) => {
        let data = action.payload;

        let list = [];

        if (data && data.res) {
            data.res.forEach(item => {
                let _item = {
                    id: item.id,
                    studentEmail: item.studentEmail,
                    isVerified: item.isVerified,
                    createdAt: item.createdAt,
                    schoolId: item.schoolId,
                    userId: item.userId,
                };
                list.push(_item);
            });
        }
        return {
            ...state,
            userStudentVerificationByEmail: {
                ...state.userStudentVerificationByEmail,
                [data.email]: list,
            },
        };
    },
};

export default createReducer(initialState, handlers);
