const colorArrayLight = {
    main: '#a80533',
    mainLight: '#c1063b',
    mainDark: '#8f042b',
    secondary: '#4b5864',
    lightGray: '#4b5864',
    mainText: '#000000',
    secondaryText: '#eee',
    gray: '#999999',
    mainBackground: '#edeeef',
    moduleBackground: '#fff',
    separatorColor: '#ccc',
    listOne: '#eee',
    listTwo: '#ddd',
    listThree: '#ccc',
    textLink: '#527096',
    mode: 'light',
};

const colorArrayDark = {
    main: '#1f1f1f',
    mainLight: '#222222',
    mainDark: '#000000',
    secondary: '#000810',
    lightGray: '#4b5864',
    mainText: '#fff',
    secondaryText: '#eee',
    gray: '#999999',
    mainBackground: '#2f2f2f',
    moduleBackground: '#222222',
    separatorColor: '#555',
    listOne: '#222',
    listTwo: '#111',
    listThree: '#333',
    textLink: '#527096',
    mode: 'dark',
};

// const colorArrayAlex = {
//     main: '#3300CC',
//     mainLight: '#3300FF',
//     mainDark: '#330099',
//     secondary: '#339933',
//     lightGray: '#4b5864',
//     mainText: '#000000',
//     secondaryText: '#eee',
//     gray: '#999999',
//     mainBackground: '#33FFFF',
//     moduleBackground: '#33CCFF',
//     separatorColor: '#ccc',
//     listOne: '#33FF99',
//     listTwo: '#33FF66',
//     listThree: '#33FFCC',
//     textLink: '#527096',
//     mode: 'light',
// };

const colorArray =
    localStorage.getItem('ee') === '1' ? colorArrayDark : colorArrayLight;

export default colorArray;
