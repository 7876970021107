import createReducer from '../createReducer';

const initialState = {
    schoolSettings: {},
    schoolSettingsLoaded: false,
    toggles: {},
    togglesLoaded: false,
};

const handlers = {
    LOAD_FEATURE_TOGGLE_SUCCESS: (state, action) => {
        const data = action.payload.supportedToggles;

        return {
            ...state,
            toggles: JSON.stringify(data, null, 4),
            togglesLoaded: true,
        };
    },
    LOAD_LOC_CONFIG_REQUEST: state => {
        return {
            ...state,
            schoolSettingsLoaded: false,
        };
    },
    LOAD_LOC_CONFIG_SUCCESS: (state, action) => {
        const data = action.payload;

        const res = {
            ...data,
            toggles: JSON.stringify(data.toggles, null, 4),
            customProperties: data.customProperties
                ? data.customProperties
                : {},
        };

        return {
            ...state,
            schoolSettings: {
                ...state.schoolSettings,
                [res.schoolId]: res,
            },
            schoolSettingsLoaded: true,
        };
    },
    UPDATE_LOC_CONFIG_SUCCESS: (state, action) => {
        const data = action.payload;

        const res = {
            ...data,
            toggles: JSON.stringify(data.toggles, null, 4),
            customProperties: data.customProperties
                ? data.customProperties
                : {},
        };

        return {
            ...state,
            schoolSettings: {
                ...state.schoolSettings,
                [res.schoolId]: res,
            },
            schoolSettingsLoaded: true,
        };
    },
};

export default createReducer(initialState, handlers);
