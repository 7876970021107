const monthFull = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const monthShort = monthFull.map(month => month.substr(0, 3) + '.');

export const formatDateTime = d => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = '' + d.getFullYear();

    let hours = '' + d.getHours();
    let minutes = '' + d.getMinutes();
    let seconds = '' + d.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const beautifyDateTime = d => {
    let month = monthShort[d.getMonth()];
    let day = '' + d.getDate();
    let year = '' + d.getFullYear();

    let hours = '' + d.getHours();
    let minutes = '' + d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return `${day} ${month} ${year} ${hours}:${minutes}`;
};

export const beautifyDate = (d, excludeYear = false) => {
    let month = monthShort[d.getMonth()];
    let day = '' + d.getDate();
    let year = '' + d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day} ${month} ${excludeYear ? '' : year}`;
};
