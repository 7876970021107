import createReducer from '../createReducer';

const initialState = {
    loaded: false,
    data: {},
    list: [],
    errors: null,
    vfairusers: {
        list: [],
        loaded: false,
    },
    vfairOrgs: {
        list: [],
        groupList: {},
        loaded: false,
    },
    vfairSchoolsForVfair: {
        list: [],
        all: {},
        loaded: false,
    },
};

function getVisibilityLabel(vFair) {
    if (vFair.visibility === 2) {
        return 'Open';
    } else if (vFair.visibility === 1 || vFair.public === true) {
        return 'Network';
    } else {
        return 'Private';
    }
}

function getVisibility(vFair) {
    if (vFair.visibility > 1) {
        return vFair.visibility.toString();
    } else {
        return vFair.public ? '1' : '0';
    }
}

const handlers = {
    LOAD_VFAIRS_SUCCESS: (state, action) => {
        const data = action.payload;
        const all = {};
        const list = [];

        data.forEach(item => {
            let _item = {
                ...item,
                active_numeric: item.active ? 1 : 0,
                visibility: getVisibility(item),
                visibility_label: getVisibilityLabel(item),
                vFairType: item.vFairType.toString(),
                profileRequirementLevel: item.profileRequirementLevel || null,
                studentVisibility_numeric: item.studentVisibility ? 1 : 0,
                start_date: item.start_date
                    ? item.start_date.replace('Z', '')
                    : null,
                end_date: item.end_date ? item.end_date.replace('Z', '') : null,
            };
            list.push(_item);
            all[item.id] = _item;
        });

        return {
            ...state,
            data: all,
            list: list,
            loaded: true,
        };
    },
    LOAD_VFAIRUSERS_REQUEST: (state, action) => {
        return {
            ...state,
            vfairusers: {
                list: [],
                loaded: false,
            },
        };
    },
    LOAD_VFAIRUSERS_SUCCESS: (state, action) => {
        let data = action.payload;

        let res = [];

        data.forEach(item => {
            res.push(item.userId);
        });

        return {
            ...state,
            vfairusers: {
                list: res,
                loaded: true,
            },
        };
    },
    LOAD_VFAIRORGS_ALL_REQUEST: (state, action) => {
        return {
            ...state,
            vfairOrgs: {
                ...initialState.vfairOrgs,
            },
        };
    },
    LOAD_VFAIRORGS_ALL_SUCCESS: (state, action) => {
        let data = action.payload;
        let list = [];
        let groupList = {};

        if (data) {
            data.forEach(item => {
                list.push(item);
                if (!groupList[item.vfairId]) {
                    groupList[item.vfairId] = [item];
                } else {
                    groupList[item.vfairId].push(item);
                }
            });
        }

        return {
            ...state,
            vfairOrgs: {
                list: list,
                groupList: groupList,
                loaded: true,
            },
        };
    },
    LOAD_VFAIRS_SCHOOLS_REQUEST: (state, action) => {
        return {
            ...state,
            vfairSchoolsForVfair: {
                ...initialState.vfairSchoolsForVfair,
            },
        };
    },
    LOAD_VFAIRS_SCHOOLS_SUCCESS: (state, action) => {
        let data = action.payload;
        let list = [];
        let all = {};

        if (data) {
            data.forEach(item => {
                list.push(item);
                all[item.schoolId] = item;
            });
        }
        return {
            ...state,
            vfairSchoolsForVfair: {
                list: list,
                all: all,
                loaded: true,
            },
        };
    },
    UPDATE_VFAIR_SUCCESS: (state, action) => {
        let data = action.payload;

        let newList = state.vfairSchoolsForVfair.list;
        let newAll = state.vfairSchoolsForVfair.all;

        if (data) {
            let _data = {
                vfairId: data.vfairId,
                schoolId: data.school_id,
                exposed: data.exposed,
            };
            newAll[data.school_id] = _data;
            let index = newList.findIndex(
                item => item.schoolId === data.school_id
            );
            if (index > -1) {
                newList[index] = _data;
            } else {
                newList.push(_data);
            }
        }

        return {
            ...state,
            vfairSchoolsForVfair: {
                list: newList,
                all: newAll,
                loaded: true,
            },
        };
    },
};

export default createReducer(initialState, handlers);
