/**
 * @flow
 */

const commonOptions = {
    client_id: 'backoffice',
    client_secret: 'not_a_secret',
    response_type: 'code',
    scope: 'openid profile role api1 email',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
};

const umSettingsDebug = {
    authority: 'https://identity-dev.highered.global',
    redirect_uri: 'http://localhost:3000/signin-oidc',
    post_logout_redirect_uri: 'http://localhost:3000/login',
    silent_redirect_uri: `http://localhost:3000/silent_renew.html`,
    ...commonOptions,
};

const umSettingsDevelopment = {
    authority: 'https://identity-dev.highered.global',
    redirect_uri: 'https://dev.higheredcms.com/signin-oidc',
    post_logout_redirect_uri: 'https://dev.higheredcms.com/login',
    silent_redirect_uri: `https://dev.higheredcms.com/silent_renew.html`,
    ...commonOptions,
};

const umSettingsProduction = {
    authority: 'https://identity.highered.global',
    redirect_uri: 'https://higheredcms.com/signin-oidc',
    post_logout_redirect_uri: 'https://higheredcms.com/login',
    silent_redirect_uri: `https://higheredcms.com/silent_renew.html`,
    ...commonOptions,
};

export default process.env.REACT_APP_ENV === 'dev'
    ? umSettingsDebug
    : process.env.REACT_APP_ENV === 'staging'
    ? umSettingsDevelopment
    : umSettingsProduction;
