import createReducer from '../createReducer';

const initialState = {
    data: {},
    error: null,
    loaded: false,
    running: false,
};

const handlers = {
    RETRIEVE_TALENT_REQUEST: (state, action) => {
        return {
            ...state,
            running: true,
            loaded: false,
            error: null,
        };
    },
    RETRIEVE_TALENT_SUCCESS: (state, action) => {
        return {
            ...state,
            running: false,
            loaded: true,
            data: action.payload,
        };
    },
    RETRIEVE_TALENT_FAILURE: (state, action) => {
        return {
            ...state,
            running: false,
            loaded: false,
            error: action.payload,
            data: {},
        };
    },
};

export default createReducer(initialState, handlers);
