import { createMuiTheme } from '@material-ui/core/styles';
import colorArray from './components/typography/colors';

export const theme = createMuiTheme({
    type: colorArray.mode,
    palette: {
        type: colorArray.mode,
        primary: {
            main: colorArray.main,
        },
        secondary: {
            main: '#2196f3',
        },
    },
});
