import createReducer from '../createReducer';

const initialState = {
    data: {},
    maxRevs: 0,
    error: null,
    loaded: false,
};

const handlers = {
    LOAD_HELP_SUCCESS: (state, action) => {
        console.log('LOAD_HELP_SUCCESS');

        let data = action.payload;

        let docs = {};

        data.sort().reverse();

        data.forEach((item, index) => {
            docs[index] = {
                raw: item.data().raw,
                timestamp: item.id.substr(1, item.id.length - 1),
            };
        });

        let maxRevs = data.length - 1;

        return {
            data: docs,
            maxRevs: maxRevs,
            error: null,
            loaded: true,
        };
    },
    LOAD_HELP_FAILURE: (state, action) => {
        console.log('LOAD_HELP_FAILURE');

        console.log('Failed Loading Help Page: ', action.payload);
        return {
            ...state,
        };
    },
};

export default createReducer(initialState, handlers);
