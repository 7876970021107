import createReducer from '../createReducer';

const initialState = {
    loaded: false,
    data: {},
    list: [],
    errors: null,
};

const handlers = {
    LOAD_COUNTRIES_SUCCESS: (state, action) => {
        console.log('LOAD_COUNTRIES_SUCCESS');

        const data = action.payload;

        const all = {};

        const list = [];

        Object.keys(data).forEach(key => {
            const item = {
                text: data[key].text,
                id: parseInt(data[key].id),
                code: data[key].code,
            };
            list.push(item);
            all[key] = item;
        });

        return {
            ...state,
            data: all,
            list: list,
            loaded: true,
        };
    },
};

export default createReducer(initialState, handlers);
