import React, { Component } from 'react';
import { processSilentRenew } from 'redux-oidc';

class SilentRenew extends Component {
    render() {
        processSilentRenew();
        return <script />;
    }
}

export default SilentRenew;
