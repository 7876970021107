import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import bgImg from '../../../assets/bg.png';
import Logo from '../../../assets/logo.png';
import userManager from '../../../auth/IdentityServer';
import Loading from '../../typography/Loading';
import ModuleWrapper from '../../typography/ModuleWrapper';
import { themeColor, themeElements } from '../../typography/theme';
import LoginForm from './LoginForm';

const Content = styled.div`
    padding: 25px;
    width: 80%;
    margin: auto;
`;

const LoginPageWrapper = styled.div`
    background-color: ${themeColor.login.backgroundColor};
    margin: 0;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const LoginFormWrapper = styled(ModuleWrapper)`
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    box-shadow: ${themeElements.boxShadowWrapper};
    background-image: url(${bgImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const LogoWrapper = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`;

const LogoImg = styled.img`
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 50px;
`;

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        try {
            await userManager.signinSilent();
            this.setState({ loading: false });
        } catch (e) {
            this.setState({ loading: false });
        }
    }

    validUser() {
        const { user } = this.props;
        return (
            user &&
            user.profile &&
            user.profile.role &&
            (user.profile.role.includes('HigherEdEmployee') ||
                user.profile.role.includes('Admin'))
        );
    }

    innerRender() {
        const { location, user } = this.props;

        if (!user) {
            return <LoginForm />;
        } else if (
            (location && location.state && location.state.status === 401) ||
            !this.validUser()
        ) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <h2>No Access</h2>
                    {/* eslint-disable-next-line */}
                    <a
                        //eslint-disable-next-line
                        href="javascript:void(0);"
                        onClick={() => userManager.signoutRedirect()}
                    >
                        Click to logout
                    </a>
                </div>
            );
        } else if (user) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <h2>Already logged in.</h2>
                    <Link to={'/'}>Back to the dashboard</Link>
                </div>
            );
        } else {
            return <LoginForm />;
        }
    }

    render() {
        // return (
        //     <LoginPageWrapper>
        //         <div>
        //             <LoginFormWrapper>
        //                 <Flex>
        //                     <Box w={[1,1/2]} p={1}>
        //                         <LogoImg src={Logo} alt={"highered-logo"} />
        //                     </Box>
        //                     <Box w={[1,1/2]} p={1}>
        //                         {this.innerRender()}
        //                     </Box>
        //                 </Flex>
        //             </LoginFormWrapper>
        //         </div>
        //     </LoginPageWrapper>
        // );

        return !this.state.loading ? (
            <LoginPageWrapper>
                <div>
                    <LoginFormWrapper>
                        <LogoWrapper>
                            <LogoImg src={Logo} alt={'highered-logo'} />
                        </LogoWrapper>
                        <Content>{this.innerRender()}</Content>
                    </LoginFormWrapper>
                </div>
            </LoginPageWrapper>
        ) : (
            <Loading />
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.oidc.user,
    };
};

export default connect(mapStateToProps)(Login);
