import React, { Component } from 'react';
import styled from 'styled-components';
import HigheredLogo from '../../../assets/highered-logo-white.png';
import userManager from '../../../auth/IdentityServer';

const LoginFormWrapper = styled.div``;

// const TextField = styled(TextFieldUI)`
//     margin-top: 5px !important;
//     margin-bottom: 10px !important;
// `;

const HigheredLoginButton = styled.button`
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #a80533;
    color: white;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: thin solid #fff !important;
    white-space: nowrap;
    cursor: pointer;
    outline: 0;
    &:hover {
        background-color: #97042d;
        transition: background-color 0.2s;
    }
    &:active {
        background-color: #7c0426;
    }
`;

const HigheredLoginBtnLogo = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 30px;
    padding: 10px;
    vertical-align: middle;
`;

const HigheredLoginBtnSpan = styled.span`
    vertical-align: middle;
`;

// const Title = styled.h2`
//     margin-top: 5px;
//     margin-bottom: 15px;
//     text-align: center;
// `;

class LoginForm extends Component {
    handleSubmit = event => {
        event.preventDefault();
        userManager.signinRedirect();
    };

    render() {
        return (
            <LoginFormWrapper>
                <form onSubmit={this.handleSubmit}>
                    {/*<TextField variant="outlined" fullWidth label="Username" />*/}
                    {/*<TextField variant="outlined" fullWidth label="Password" type="password" />*/}
                    {/*<Button variant="contained" type={'submit'} fullWidth color="primary">Login with Highered</Button>*/}
                    {/*<Title>*/}
                    {/*Highered Administration Dashboard*/}
                    {/*</Title>*/}
                    <HigheredLoginButton>
                        <HigheredLoginBtnLogo src={HigheredLogo} />
                        <HigheredLoginBtnSpan>
                            Login with Highered
                        </HigheredLoginBtnSpan>
                    </HigheredLoginButton>
                </form>
            </LoginFormWrapper>
        );
    }
}

export default LoginForm;
