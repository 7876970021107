import React, { Component } from 'react';
import './App.css';
import Root from './authlayer';
import history from './components/core/utils/history';
import { Route, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './store';

import { OidcProvider } from 'redux-oidc';
import userManager from './auth/IdentityServer';
import Login from './components/pages/Login';
import Callback from './components/pages/Callback/Callback';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './muitheme';

// Google Analytics
import ReactGA from 'react-ga';
import SilentRenew from './auth/SilentRenew';
let env = process.env.REACT_APP_ENV;
let trackingCode =
    env === 'dev'
        ? 'UA-139858791-2'
        : env === 'staging'
        ? 'UA-139858791-1'
        : 'UA-139858791-1';
ReactGA.initialize(trackingCode);

class App extends Component {
    render() {
        history.listen(location => ReactGA.pageview(location.pathname));

        return (
            <Provider store={store}>
                <OidcProvider store={store} userManager={userManager}>
                    <MuiThemeProvider theme={theme}>
                        <div>
                            <ToastContainer />
                            <Router history={history}>
                                <div>
                                    <Route
                                        path="/signin-oidc"
                                        component={Callback}
                                    />
                                    <Route path="/login" component={Login} />
                                    <Route
                                        path={'/silent_renew.html'}
                                        component={SilentRenew}
                                    />
                                    <Root />
                                </div>
                            </Router>
                        </div>
                    </MuiThemeProvider>
                </OidcProvider>
            </Provider>
        );
    }
}

export default App;
