import styled from 'styled-components';
import { themeColor, themeElements } from './theme';

const ModuleWrapper = styled.div`
    ${themeElements.boxShadow};
    margin: 30px;
    background-color: ${themeColor.main.innerBackground};
    // ${props => (props.disableoverflowhidden ? '' : 'overflow:hidden;')}
`;

export default ModuleWrapper;
