import createReducer from '../createReducer';

const initialState = {
    schools: {
        result: [],
        loaded: false,
    },
};

const handlers = {
    LOAD_SCHOOL_DATA_ARRAY: (state, action) => {
        return {
            schools: {
                result: action.payload,
                loaded: true,
            },
        };
    },
};

export default createReducer(initialState, handlers);
