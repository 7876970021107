import createReducer from '../createReducer';
import { formatDateTime } from '../utils/formatDate';

const initialState = {
    companies: {
        all: {},
        list: [],
    },
    unverifiedCompanies: {
        all: {},
        list: [],
    },
    schools: {
        all: {},
        list: [],
    },
    uncategorized: [],
    error: null,
    loaded: false,
    profile: {
        articles: {
            loaded: false,
            all: {},
            list: [],
        },
        videos: {
            loaded: false,
            all: {},
            list: [],
        },
    },
    relations: {
        all: {},
        list: [],
        loaded: false,
    },
    videoChatOrganization: {
        loaded: false,
        error: false,
        enabled: undefined,
    },
    featurepermissions: [],
};

const getSchoolArrkeyByOrgKey = (schools, orgKey) => {
    return Object.keys(schools).find(
        key => schools[key].organizationSettingsId === orgKey
    );
};

const splitDomains = seData => {
    if (
        !seData ||
        !seData.domains ||
        (seData.domains && seData.domains.length < 1)
    ) {
        return {
            emails: [],
            domains: [],
        };
    }

    let domains = seData.domains;

    let arr = [];
    if (domains.includes(';')) {
        arr = domains.split(';');
    } else {
        arr.push(domains);
    }

    let emailWL = [];
    let domainWL = [];

    Object.keys(arr).forEach(key => {
        if (arr[key].includes('@')) {
            let a = arr[key].split('@');
            if (a[0].length > 0) emailWL.push(arr[key]);
        } else {
            domainWL.push(arr[key]);
        }
    });

    return { emails: emailWL, domains: domainWL };
};

const makeCompanyTree = (a, parentId) => {
    let children = [];
    a.filter(item => {
        return item.parentCompanyId === parentId;
    }).forEach(item => {
        let _item = {
            id: item.id,
            title: item.title,
            children: makeCompanyTree(a, item.id),
        };
        children.push(_item);
    });
    return children;
};

const handlers = {
    LOAD_ORGANIZATIONS_REQUEST: (state, action) => {
        return state;
    },
    ORGANIZATIONS_START_UPDATE: (state, action) => {
        return {
            ...state,
            updating: true,
        };
    },
    LOAD_ORGANIZATIONS_SUCCESS: (state, action) => {
        const data = action.payload.orgs;
        const total = action.payload.total;
        const seData = action.payload.se;
        const addressData = action.payload.addresses;
        let companies = [];
        let companiesObj = {};
        let schools = [];
        let schoolsObj = {};
        const uncategorized = [];
        let unverifiedCompanies = [];
        let unverifiedCompaniesObj = {};
        if (data) {
            Object.keys(data).forEach(key => {
                const org = {
                    ...data[key],
                    addresses: addressData.filter(
                        a => a.companyId === parseInt(data[key].id)
                    ),
                };
                if (org.category.id === 2) {
                    const seId = getSchoolArrkeyByOrgKey(seData, org.id);
                    const item = {
                        ...org,
                        ...state.companies.all[org.id],
                        ...seData[seId],
                        id: parseInt(org.id),
                        title: org.title,
                        description: org.description,
                    };
                    companies.push(item);
                    companiesObj[org.id] = item;
                    if (!org.verified) {
                        unverifiedCompanies.push(item);
                        unverifiedCompaniesObj[org.id] = item;
                    }
                } else if (org.category.id === 1) {
                    const seId = getSchoolArrkeyByOrgKey(seData, org.id);
                    let domains;
                    domains = splitDomains(seData[seId]);
                    schools.push({
                        ...org,
                        ...seData[seId],
                        domains: domains.domains,
                        emails: domains.emails,
                    });
                    schoolsObj[org.id] = {
                        ...org,
                        ...seData[seId],
                        domains: domains.domains,
                        emails: domains.emails,
                    };
                } else {
                    uncategorized.push(org);
                }
            });
        }
        const tree = makeCompanyTree(companies, null);

        companies.sort((a, b) => {
            return a.id - b.id;
        });
        schools.sort((a, b) => {
            return a.id - b.id;
        });
        uncategorized.sort((a, b) => {
            return a.id - b.id;
        });

        return {
            ...state,
            companies: {
                all: companiesObj,
                list: companies,
                tree: tree,
                total,
            },
            unverifiedCompanies: {
                all: unverifiedCompaniesObj,
                list: unverifiedCompanies,
            },
            schools: {
                all: schoolsObj,
                list: schools,
            },
            uncategorized: uncategorized,
            error: null,
            loaded: true,
            updating: false,
        };
    },
    POST_ORGANIZATION_SETTINGS_POST_SUCCESS: (state, action) => {
        console.log(
            '***POST_ORGANIZATION_SETTINGS_POST_SUCCESS',
            action.payload
        );

        const organizationSettings = action.payload;

        let newCompanyData = {
            ...state.companies.all[organizationSettings.organizationSettingsId],
            ...organizationSettings,
        };

        return {
            ...state,
            companies: {
                ...state.companies,
                all: {
                    ...state.companies.all,
                    [organizationSettings.organizationSettingsId]: newCompanyData,
                },
            },
        };
    },
    LOAD_ORGANIZATION_RELATIONS_REQUEST: (state, action) => {
        return {
            ...state,
            relations: {
                all: {},
                list: [],
                loaded: false,
            },
        };
    },
    LOAD_ORGANIZATION_RELATIONS_SUCCESS: (state, action) => {
        const data = action.payload;
        const obj = {};
        const list = [];

        if (data) {
            data.forEach(rel => {
                list.push(rel);
                obj[rel.secondOrganizationId] = rel;
            });
        }

        return {
            ...state,
            relations: {
                all: obj,
                list: list,
                loaded: true,
            },
        };
    },
    LOAD_ORG_ARTICLE_SUCCESS: (state, action) => {
        const data = action.payload;
        const list = [];
        const all = {};

        if (data) {
            data.forEach(item => {
                let _item = {
                    ...item,
                    createdAt: formatDateTime(new Date(item.createdAt)),
                    updatedAt: formatDateTime(new Date(item.updatedAt)),
                };
                if (!item.vfairIds) {
                    _item.vfairIds = [];
                }
                list.push(_item);
                all[item.slug] = _item;
            });
        }

        return {
            ...state,
            profile: {
                ...state.profile,
                articles: {
                    loaded: true,
                    list: list,
                    all: all,
                },
            },
        };
    },
    LOAD_ORG_ARTICLE_DETAILS_REQUEST: (state, action) => {
        return {
            ...state,
            profile: {
                ...state.profile,
                articles: {
                    ...state.profile.articles,
                    loaded: false,
                },
            },
        };
    },
    LOAD_ORG_ARTICLE_DETAILS_SUCCESS: (state, action) => {
        const data = action.payload;
        if (!action.payload.vfairIds) {
            data.vfairIds = [];
        }
        return {
            ...state,
            profile: {
                ...state.profile,
                articles: {
                    loaded: true,
                    all: {
                        ...state.profile.articles.all,
                        [data.id]: data,
                    },
                    list: state.profile.articles.list,
                },
            },
        };
    },
    LOAD_ORG_VIDEO_SUCCESS: (state, action) => {
        const data = action.payload;
        const list = [];
        const all = {};

        if (data) {
            data.forEach(item => {
                let extData = {};
                if (
                    state &&
                    state.profile &&
                    state.profile.videos &&
                    state.profile.videos.all &&
                    state.profile.videos.all[item.id]
                ) {
                    extData = state.profile.videos.all[item.id];
                }
                let _item = {
                    ...extData,
                    ...item,
                    createdAt: formatDateTime(new Date(item.createdAt)),
                    updatedAt: formatDateTime(new Date(item.updatedAt)),
                };
                list.push(_item);
                all[item.id] = _item;
            });
        }

        return {
            ...state,
            profile: {
                ...state.profile,
                videos: {
                    loaded: true,
                    list: list,
                    all: all,
                },
            },
        };
    },
    LOAD_ORG_VIDEO_DETAILS_REQUEST: (state, action) => {
        return {
            ...state,
            profile: {
                ...state.profile,
                videos: {
                    ...state.profile.videos,
                    loaded: false,
                },
            },
        };
    },
    LOAD_ORG_VIDEO_DETAILS_SUCCESS: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            profile: {
                ...state.profile,
                videos: {
                    loaded: true,
                    all: {
                        ...state.profile.videos.all,
                        [data.id]: data,
                    },
                    list: state.profile.videos.list,
                },
            },
        };
    },
    POST_ORGANIZATION_SUCCESS: (state, action) => {
        console.log('POST_ORGANIZATION_SUCCESS');

        if (action.companyUpdate) {
            const data = action.payload;
            let newList = state.companies.list;
            let newAll = state.companies.all;

            if (data && data.id) {
                const org = {
                    ...state.companies.all[data.id],
                    ...data,
                };
                newList.push(org);
                newAll[org.id] = org;

                let index = newList.findIndex(item => item.id === data.id);
                if (index > -1) {
                    newList[index] = org;
                } else {
                    newList.push(org);
                }
            }

            return {
                ...state,
                companies: {
                    ...state.companies,
                    list: newList,
                    all: newAll,
                    loaded: true,
                },
            };
        }

        const postedId = action.payload;
        return {
            ...state,
            postedId: postedId,
        };
    },

    POST_ORGANIZATION_FAILURE: (state, action) => {
        const error = action.payload;
        return { ...state, updateError: error };
    },

    SET_NEW_ORG_DATA: (state, action) => {
        console.log('SET_NEW_ORG_DATA');
        const data = action.payload;
        let schoolObj;

        let domains;
        domains = splitDomains(data);
        schoolObj = {
            ...data,
            domains: domains.domains,
            emails: domains.emails,
        };

        console.log('Set vals', schoolObj);

        return {
            ...state,
            schools: {
                all: {
                    ...state.schools.all,
                    [data.id]: schoolObj,
                },
                list: state.schools.list,
            },
        };
    },
    LOAD_PROFILE_SUCCESS: (state, action) => {
        const data = action.payload;
        let newData = {
            ...state.companies.all[data.organizationId],
        };

        if (data) {
            newData['banner'] = data.banner;
        }

        return {
            ...state,
            companies: {
                ...state.companies,
                all: {
                    ...state.companies.all,
                    [data.organizationId]: newData,
                },
            },
        };
    },
    LOAD_PROFILE_FEATURE_PERMISSIONS_SUCCESS: (state, action) => {
        const data = action.payload || { permissions: [] };

        return {
            ...state,
            featurepermissions: data.permissions || [],
        };
    },
    LOAD_VIDEO_CHAT_CONFIG_REQUEST: (state, action) => {
        return {
            ...state,
            videoChatOrganization: {
                loaded: false,
                error: false,
                enabled: undefined,
            },
        };
    },
    LOAD_VIDEO_CHAT_CONFIG_SUCCESS: (state, action) => {
        const data = action.payload.result;

        let enabled = false;

        if (data && data.enabled && data.enabled === true) enabled = true;

        return {
            ...state,
            videoChatOrganization: {
                loaded: true,
                error: false,
                enabled: enabled,
            },
        };
    },
    LOAD_VIDEO_CHAT_CONFIG_FAILURE: (state, action) => {
        return {
            ...state,
            videoChatOrganization: {
                loaded: false,
                error: true,
                enabled: undefined,
            },
        };
    },
};

export default createReducer(initialState, handlers);
