import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { themeColor } from './theme';

const LoadOuter = styled.div`
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-box-flex-direction: row;
    -moz-box-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 8%;
    width: 100%;
    height: 100%;
`;

const LoadInner = styled.div`
    margin: auto;
`;

const Loading = ({ width = 100, height = 100 }) => (
    <LoadOuter>
        <LoadInner>
            <Loader
                type="Bars"
                color={themeColor.loader}
                height={height}
                width={width}
            />
        </LoadInner>
    </LoadOuter>
);
export default Loading;
