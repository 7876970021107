import organizationsReducer from './organizations/reducer';
import helpReducer from './help/reducer';
import positionsReducer from './positions/reducer';
import resourcesReducer from './resources/reducer';
import promotionalVideoReducer from './organizations/promotionalVideos/reducer';
import articleReducer from './articles/reducer';
import usersReducer from './users/reducer';
import newslettersReducer from './newsletters/reducer';
import countriesReducer from './countries/reducer';
import devReducer from './dev/reducer';
import retrieveStudentsReducer from './retrieve-students/reducer';
import localizationReducer from './localization/reducer';
import vfairReducer from './vfairs/reducer';

const commonReducer = {
    organizations: organizationsReducer,
    help: helpReducer,
    positions: positionsReducer,
    resources: resourcesReducer,
    promotionalVideos: promotionalVideoReducer,
    articles: articleReducer,
    users: usersReducer,
    newsletters: newslettersReducer,
    countries: countriesReducer,
    dev: devReducer,
    retrieveStudents: retrieveStudentsReducer,
    localization: localizationReducer,
    vfair: vfairReducer,
};

export default commonReducer;
