import createReducer from '../createReducer';

const initialState = {
    data: {},
    list: [],
    error: null,
    loaded: false,
};

const handlers = {
    LOAD_ARTICLE_SUCCESS: (state, action) => {
        let data = action.payload;

        let list = [];

        Object.keys(data).forEach(key => {
            list.push(data[key]);
        });

        return {
            list: list,
            data: data,
            error: null,
            loaded: true,
        };
    },
    LOAD_ARTICLE_FAILURE: (state, action) => {
        console.log('LOAD_ARTICLE_FAILURE');

        console.log('Failed Loading Articles: ', action.payload);
        return {
            ...state,
            loaded: true,
            error: action.payload,
        };
    },

    UPDATE_ARTICLE_SUCCESS: (state, action) => {
        console.log('UPDATE_ARTICLE_SUCCESS');

        let prevData = state.data;

        let newData = action.payload;
        console.log('NEW DATA', newData);

        return {
            ...state,
            data: {
                ...prevData,
                [newData.articleId]: newData,
            },
        };
    },
};

export default createReducer(initialState, handlers);
