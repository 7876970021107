import Login from './components/pages/Login';
import Loading from './components/typography/Loading';
import connect from 'react-redux/es/connect/connect';
import React, { Component, Suspense } from 'react';
const Routes = React.lazy(() => import('./routes'));

class AuthLayer extends Component {
    render() {
        const { user } = this.props;
        return user &&
            user.profile &&
            user.profile.role &&
            (user.profile.role.includes('HigherEdEmployee') ||
                user.profile.role.includes('Admin')) ? (
            <Suspense fallback={<Loading />}>
                <Routes />
            </Suspense>
        ) : user ? (
            <div>
                <Login invalid />
            </div>
        ) : window.location.pathname === '/signin-oidc' ? (
            <Loading />
        ) : (
            <div>
                <Login />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.oidc.user,
    };
};

export default connect(mapStateToProps, null)(AuthLayer);
