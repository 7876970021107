import createReducer from '../createReducer';

const initialState = {
    data: {},
    maxRevs: 0,
    error: null,
    loaded: false,
};

const handlers = {
    LOAD_RESOURCE_SUCCESS: (state, action) => {
        console.log('LOAD_RESOURCE_SUCCESS');

        let data = action.payload;

        return {
            data: data,
            error: null,
            loaded: true,
        };
    },
    LOAD_RESOURCE_FAILURE: (state, action) => {
        console.log('LOAD_RESOURCE_FAILURE');

        console.log('Failed Loading Help Page: ', action.payload);
        return {
            ...state,
        };
    },
};

export default createReducer(initialState, handlers);
