import colorArray from './colors';

export const themeColor = {
    header: colorArray.main,
    red: colorArray.main,
    darkRed: colorArray.mainDark,
    footer: {
        backgroundColor: colorArray.secondary,
        textColor: colorArray.secondaryText,
    },
    border: colorArray.secondary,
    lightBorder: colorArray.gray,
    navigation: {
        background: colorArray.main,
        textColor: colorArray.secondaryText,
        backgroundHover: colorArray.mainLight,
        textHover: colorArray.textLink,
        activeBg: colorArray.mainLight,
        subBg: colorArray.mainDark,
    },
    main: {
        background: colorArray.mainBackground,
        innerBackground: colorArray.moduleBackground,
    },
    separator: colorArray.separatorColor,
    list: {
        header: {
            background: colorArray.secondary,
            textColor: colorArray.secondaryText,
        },
        row: {
            primaryBackground: colorArray.listOne,
            secondaryBackground: colorArray.listTwo,
            hoverBackground: colorArray.listThree,
            textColor: colorArray.mainText,
        },
        pagination: {
            border: colorArray.separatorColor,
            backgroundColor: colorArray.listOne,
            backgroundColorActive: colorArray.listTwo,
            backgroundColorHover: colorArray.listThree,
            textColor: colorArray.mainText,
        },
    },
    filter: {
        linkColor: colorArray.textLink,
    },
    login: {
        backgroundColor: colorArray.main,
    },
    loader: colorArray.main,
    textColor: colorArray.mainText,
    mainBackground: colorArray.mainBackground,
    linkText: colorArray.textLink,
};

export const themeElements = {
    boxShadow:
        'box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)',
    boxShadowWrapper: '0 0 10px 4px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)',
};
