import createReducer from '../createReducer';

const initialState = {
    error: null,
    loaded: false,
    list: [],
    data: {},
    cts: {
        data: {},
        loaded: false,
    },
    current: {
        data: [],
        totalCount: null,
    },
};

const handlers = {
    LOAD_CTS_POSITIONS_REQUEST: state => {
        return {
            ...state,
            cts: {
                loaded: false,
                data: {},
            },
        };
    },

    LOAD_CTS_POSITIONS_SUCCESS: (state, action) => {
        return {
            ...state,
            cts: {
                loaded: true,
                data: action.payload,
            },
        };
    },
    LOAD_POSITION_REQUEST: (state, action) => {
        return {
            ...state,
            loaded: false,
        };
    },
    LOAD_POSITION_SUCCESS: (state, action) => {
        const data = action.payload;

        let res;

        if (data) {
            res = {
                ...state.data[data.id],
                ...data,
            };
        }

        return {
            ...state,
            data: {
                ...state.data,
                [data.id]: res,
            },
            loaded: true,
        };
    },
    LOAD_POSITIONS_REQUEST: (state, action) => {
        return {
            ...state,
            loaded: false,
        };
    },
    LOAD_POSITIONS_SUCCESS: (state, action) => {
        const data = action.payload;

        const positions = {};

        let list = [];
        if (data) {
            data.data.forEach(item => {
                list.push(item);
                positions[item.id] = item;
            });
        }

        return {
            ...state,
            data: positions,
            list: list,
            current: {
                ...data,
                data: list,
            },
            error: null,
            loaded: true,
        };
    },
};

export default createReducer(initialState, handlers);
